.company-creation-form{
  margin-top: 5em;
}

// ==================================== disable arrow in input type number ======================= //
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// ============================================== END ========================================== //